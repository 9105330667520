import { PaymentTerm } from "@app/core/interfaces/payment-term.model";
import { SubscriptionType } from "@app/core/interfaces/subscription-type.model";
import { ApplicationSubscription } from "@app/core/interfaces/subscription.model";
import { User } from "@app/core/interfaces/user.model";

export interface UserStateModel extends User {
    invoiceAddress: string;
    mandate: any;
}

export class GetUser {
    static readonly type = '[User] Get';
    constructor(public payload: {userAuth0Id: string}) {
    }
}

export class UpdateUser {
    static readonly type = '[User] Update';
    constructor(public payload: {userAuth0Id: string, updatedUser: User}) {
    }
}

export class UpdateUserInvoiceAddress {
    static readonly type = '[User] UpdateUserInvoiceAddress';
    constructor(public payload: {userAuth0Id: string, invoiceAddress: string}) {
    }
}

export class ChangePaymentTerm {
    static readonly type = '[User] ChangePaymentTerm';
    constructor(public payload: {currentSubscription: ApplicationSubscription, futurePaymentTerm: PaymentTerm}) { }
}


export class GetUserInvoiceAddress {
    static readonly type = '[User] GetInvoiceAddress';
    constructor(public payload: {userAuth0Id: string}) { }
}


export class UpgradeSubscription {
    static readonly type = '[User] UpgradeSubscription';
    constructor(public payload: {currentSubscription: ApplicationSubscription, futureSubscriptionType: SubscriptionType, futurePaymentTerm: PaymentTerm}) { }
}

export class CancelSubscription {
    static readonly type = '[User] CancelSubscription';
    constructor(public payload: {subscriptionId: string}) { }
}


export class SetSubscriptionCancellationReason {
    static readonly type = '[User] SetCancellationReason';
    constructor(public payload: {cancellationReason: string, subscriptionId: string}) { }
}

export class ClearUser {
    static readonly type = '[User] Clear';
    constructor() {
    }
}

export class GetUserMandate {
    static readonly type = '[User] GetMandate';
    constructor(public payload: {userAuth0Id: string}) { }
}
