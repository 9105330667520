import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthStateModel, GetNewAccessToken, Login, LoginWithGoogle, Logout } from './models/auth.state.model';
import { AuthService } from '../services/auth.service';
import { firstValueFrom } from 'rxjs';
import { StateNames } from '../common/state-names.enum';

/* access token + refresh token are stored in local storage to provide multi-tab support */
const defaultState: AuthStateModel = {
    user_id: null,
    roles: [],
    permissions: [],
};

@State<AuthStateModel>({
    name: StateNames.authv1,
    defaults: defaultState
})
@Injectable()
export class AuthState {

    @Selector()
    static isAuthenticated(state: AuthStateModel) {
        return localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined;
    }

    @Selector()
    static accessToken(state: AuthStateModel) {
        return localStorage.getItem('access_token');
    }

    @Selector()
    static refreshToken(state: AuthStateModel) {
        return localStorage.getItem('refresh_token');
    }

    @Selector()
    static user_id(state: AuthStateModel) {
        return state.user_id;
    }

    @Selector()
    static roles(state: AuthStateModel) {
        return state.roles;
    }

    @Selector()
    static permissions(state: AuthStateModel) {
        return state.permissions;
    }

    static hasRole(name: string) {
        return createSelector(
            [AuthState.roles],
            (roles) => {
                return roles.map(role => role.role_name).includes(name);
            }
        );
    }

    static hasPermissions(requiredPermissions: string[]) {
        return createSelector(
            [AuthState.permissions],
            (permissions) => {
                return requiredPermissions.every(permission => permissions.includes(permission));
            }
        );
    }

    constructor(
        private authService: AuthService
    ) { }

    @Action(Login)
    async login(ctx: StateContext<AuthStateModel>, action: Login) {
        const state = ctx.getState();
        const response = await firstValueFrom(this.authService.login({ email: action.payload.email, password: action.payload.password }));

        localStorage.setItem('access_token', response.accessToken);
        localStorage.setItem('refresh_token', response.refreshToken);
        ctx.setState(response);
    }

    @Action(LoginWithGoogle)
    async loginWithGoogle(ctx: StateContext<AuthStateModel>, action: LoginWithGoogle) {
        const response = await firstValueFrom(this.authService.loginWithGoogle(action.payload));
        localStorage.setItem('access_token', response.accessToken);
        localStorage.setItem('refresh_token', response.refreshToken)
        ctx.setState(response);
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        ctx.setState(defaultState);
    }

    @Action(GetNewAccessToken)
    async getNewAccessToken(ctx: StateContext<AuthStateModel>) {
        const response = await firstValueFrom(this.authService.getNewAccessToken({ refreshToken:  localStorage.getItem('refresh_token') }));
        localStorage.setItem('access_token', response.accessToken);
        localStorage.setItem('refresh_token', response.refreshToken);
        ctx.patchState(response);
    }
}