import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import { switchMap, tap } from "rxjs/operators";
import { USER_PROPERTIES } from "../config/user-properties";
import { SubscriptionService } from "../services/subscription.service";
import { UserService } from '@app/core/services/user.service';
import { CancelSubscription, ChangePaymentTerm, ClearUser, GetUser, GetUserInvoiceAddress, GetUserMandate, SetSubscriptionCancellationReason, UpdateUser, UpdateUserInvoiceAddress, UpgradeSubscription, UserStateModel } from "./models/user.state.model";
import { firstValueFrom } from "rxjs";
import { ApplicationSubscription } from "../interfaces/subscription.model";
import { StateNames } from "../common/state-names.enum";


@State<UserStateModel>({
    name: StateNames.userv1,
    defaults: null
})
@Injectable()
export class UserState {
    // Return User.
    @Selector([UserState])
    static user(state: UserStateModel) {
        return state;
    }

    // Return User.
    @Selector([UserState])
    static invoiceAddress(state: UserStateModel) {
        return state.invoiceAddress || '';
    }

    @Selector([UserState])
    static allowedUserPropertiesInState(state: UserStateModel) {
        return state.allowedUserProperties;
    }

    // Return allowed user properties.
    @Selector([UserState.allowedUserPropertiesInState])
    static allowedUserProperties(allowedUserPropertiesInState: string[]) {
        return USER_PROPERTIES.filter(userProperty => allowedUserPropertiesInState.includes(userProperty.name));
    }

    // Return the subscription that is currently active.
    @Selector([UserState.user])
    static activeSubscription(state: UserStateModel) {
        if (state?.subscriptions === null || state?.subscriptions === undefined) {
            return undefined;
        }
        const subscriptions = state?.subscriptions;
        const activeSubscription = subscriptions.filter(s => s.status === 'active')[0];

        if (activeSubscription === undefined) {
            throw new Error('No active subscription for user');
        }
        return activeSubscription;
    }

    // Return the subscription that is currently active.
    @Selector([UserState.activeSubscription])
    static activeSubscriptionIsCancelled(subscription: ApplicationSubscription) {
        return subscription.end !== null;
    }

    // Return the subscription that is currently pending.
    @Selector([UserState.user])
    static pendingSubscription(state: UserStateModel) {
        if (state?.subscriptions === null || state?.subscriptions === undefined) {
            return null;
        }
        const subscriptions = state?.subscriptions;
        const activeSubscription = subscriptions.filter(s => s.status === 'pending')[0];

        return activeSubscription || null;
    }

    // Return mandate
    @Selector([UserState])
    static mandate(state: UserStateModel) {
        return state.mandate || null;
    }

    @Selector([UserState])
    static primarySubscriptionGoal(state: UserStateModel) {
        return state.primarySubscriptionGoal;
    }

    @Selector([UserState.primarySubscriptionGoal])
    static hasPrimarySubscriptionGoal(primarySubscriptionGoal: string) {
        const noGoalValues = [null, undefined, '', 'no-selection', 'homepage'];
        return noGoalValues.includes(primarySubscriptionGoal) === false;
    }

    constructor(
        private userService: UserService,
        private subscriptionService: SubscriptionService
    ) { }

    @Action(GetUser)
    getUser(ctx: StateContext<UserStateModel>, action: GetUser) {
        return this.userService.get(action.payload.userAuth0Id).pipe(
            tap(user => {
                ctx.patchState(user);
            })
        );
    }

    @Action(UpdateUser)
    updateUser(ctx: StateContext<UserStateModel>, action: UpdateUser) {
        return this.userService.update(action.payload.userAuth0Id, action.payload.updatedUser).pipe(
            tap((user) => {
                ctx.patchState(user);
            })
        )
    }


    @Action(GetUserInvoiceAddress)
    getUserInvoiceAddress(ctx: StateContext<UserStateModel>, action: GetUserInvoiceAddress) {
        return this.userService.getInvoiceAddresses(action.payload.userAuth0Id).pipe(
            tap(invoiceEmail => {
                ctx.patchState({
                    invoiceAddress: invoiceEmail
                });
            })
        )
    }

    @Action(UpdateUserInvoiceAddress)
    updateUserInvoiceAddress(ctx: StateContext<UserStateModel>, action: UpdateUserInvoiceAddress) {
        return this.userService.updateUserInvoiceAddress(action.payload.userAuth0Id, action.payload.invoiceAddress).pipe(
            tap(invoiceEmail => {
                ctx.patchState({
                    invoiceAddress: invoiceEmail
                });
            })
        );
    }

    @Action(ChangePaymentTerm)
    changePaymentTerm(ctx: StateContext<UserStateModel>, action: ChangePaymentTerm) {
        return this.subscriptionService.changePaymentTerm(action.payload.currentSubscription, action.payload.futurePaymentTerm).pipe(
            switchMap((success) => this.userService.get(action.payload.currentSubscription.userAuth0Id)),
            tap(user => {
                ctx.patchState(user);
            })
        );
    }


    @Action(UpgradeSubscription)
    upgradeSubscription(ctx: StateContext<UserStateModel>, action: UpgradeSubscription) {
        return this.subscriptionService.upgradeSubscription(
            action.payload.currentSubscription,
            action.payload.futureSubscriptionType,
            action.payload.futurePaymentTerm).pipe(
                switchMap((success) => this.userService.get(action.payload.currentSubscription.userAuth0Id)),
                tap(user => {
                    ctx.patchState(user);
                })
            );
    }

    @Action(SetSubscriptionCancellationReason)
    async setSubscriptionCancellationReason(ctx: StateContext<UserStateModel>, action: SetSubscriptionCancellationReason) {
        const subscription = await firstValueFrom(this.subscriptionService.setCancellationReason(action.payload.subscriptionId, action.payload.cancellationReason));
        const user = await firstValueFrom(this.userService.get(subscription.userAuth0Id));
        ctx.patchState(user);
    }

    @Action(CancelSubscription)
    async cancelSubscription(ctx: StateContext<UserStateModel>, action: CancelSubscription) {

        await firstValueFrom(this.subscriptionService.cancelSubscription(action.payload.subscriptionId));
        const user = await firstValueFrom(this.userService.get(ctx.getState().auth0Id));
        ctx.patchState(user);
    }


    @Action(ClearUser)
    clearUser(ctx: StateContext<UserStateModel>) {
        ctx.setState(null);
    }

    @Action(GetUserMandate)
    getUserMandate(ctx: StateContext<UserStateModel>, action: GetUserMandate) {
        return this.userService.getMandate(action.payload.userAuth0Id).pipe(
            tap(mandate => {
                ctx.patchState({
                    mandate: mandate
                });
            })
        )
    }
}
